import moment from "moment"
import { SURVEY_WIZARD_TABS } from '../../../../../../../../../config/tabConstants'
import { URL_DATA } from "../../../../../../../../../config/urlDataConstants"
import { deepCopy } from "../../../../../../../../utils/deepCopy"
import { combineDateAndTimeToISO, disabledDate, UpdateMonth } from "../../../../../../../../utils/timeUtils"
import { getDataFromUrlToken } from "../../../../../../../../utils/urlDataStore"
import constants from './constants'

export const checkIfSaveReminderButtonDisable = (instance) => {
    let {
        emailBody,
        emailSubject,
        reminderDate,
        reminderTime,
        dateEvent,
        timeEvent,
        emailHeader
    } = instance.state
    if (emailBody.trim().length !== 0 &&
        emailSubject.trim().length !== 0 &&
        emailHeader.trim().length !== 0 &&
        reminderDate !== "" &&
        reminderTime !== "" &&
        dateEvent &&
        timeEvent

    ) {
        return false
    } else {
        return true
    }
}

export const emailBodyErrorHandler = (instance) => {
    let dateIndex = instance.state.emailBody.search(constants.DATE)
    let nameIndex = instance.state.emailBody.search()
    if (dateIndex < 0 && nameIndex < 0) {
        return {
            notValid: true,
            message: constants.ERROR_MESSAGE
        }
    } else if (dateIndex < 0) {
        return {
            notValid: true,
            message: constants.ERROR_MESSAGE_FOR_DATE
        }
    } else if (nameIndex < 0) {
        return {
            notValid: true,
            message: constants.ERROR_MESSAGE_FOR_NAME
        }
    } else {
        return {
            notValid: false
        }
    }
}

export const addReminderApiPayload = (instance) => {
    let instanceCopy = deepCopy(instance.state)
    let emailBody = instanceCopy.emailBody.replace("/n", "<br/>")
    let reminderTime = getDispalyDateInIsoFormat(instanceCopy.reminderDate, instanceCopy.reminderTime)
    return {
        currentTab: SURVEY_WIZARD_TABS.WIZARD_SUB_TABS.SETUP_SUB_SECTIONS.REMINDER,
        ...getProjectAndOrgDetails(),
        email: constants.REMINDER_DEFAULT_EMAIL,
        hrisFileId: getHRISId(instance.props),
        scheduledTime: reminderTime,
        emailTemplate: emailBody,
        subject: instanceCopy.emailSubject,
        emailHeader: instanceCopy.emailHeader,
        isSelfReminder: instance.props.isSelfReminder ? instance.props.isSelfReminder : false,
        isEmbedStatusReport: instanceCopy.isEmbedStatusReport ? instanceCopy.isEmbedStatusReport : undefined,
        selfCompletedEmailDetails: instanceCopy.selfCompletedEmailDetails
    }
}

export const getProjectAndOrgDetails = () => {
    const orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION);
    const projDetails = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS);
    return {
        currentTab: SURVEY_WIZARD_TABS.WIZARD_SUB_TABS.SETUP_SUB_SECTIONS.REMINDER,
        organizationId: orgDetails.organizationId,
        projectId: projDetails.projectId,
        projectStatus: projDetails.projectStatus,
        projectOrigin: projDetails.projectOrigin
    }
}

export const getHRISId = (props) => {
    if (props.surveyWizardProjectDetailsApi.hrisData &&
        props.surveyWizardProjectDetailsApi.hrisData.hrisFileId &&
        props.surveyWizardProjectDetailsApi.hrisData.hrisFileId) {
        return props.surveyWizardProjectDetailsApi.hrisData.hrisFileId
    }
}

const getTime = (timeString, amOrPm) => {
    let hour = timeString.slice(0, 2)
    let minute = timeString.slice(3, 5)
    let second = timeString.slice(6, 8)
    if (amOrPm === "pm" && hour !== "12") {
        hour = parseInt(hour)
        hour = hour + 12
        hour = hour.toString()
    } else if (amOrPm === "am" && hour === "12") {
        hour = "00"
    }
    return `${hour}:${minute}:${second}`
}

const getTimeData = (timeData) => {
    let hour = timeData[0].length === 1 ? "0" + timeData[0] : timeData[0]
    let otherData = timeData[1].split(" ")
    let minute = otherData[0]
    let amOrPm = otherData[1]
    return {
        time: `${hour}:${minute}:00`,
        amOrPm: amOrPm
    }
}

export const getDispalyDateInIsoFormat = (date, time) => {
    let dateInMoment = moment(date, "dddd,MMMM Do YYYY")
    let timeData = time.split(":")
    let timeValue = getTimeData(timeData)
    let amPmAppliedTime = getTime(timeValue.time, timeValue.amOrPm)
    let dateString = (dateInMoment._a[0]).toString() + "-" + UpdateMonth((parseInt(dateInMoment._a[1]) + 1).toString()) + "-" + UpdateMonth((dateInMoment._a[2]).toString())
    let dateTimeInISOFormat = combineDateAndTimeToISO(dateString+"T00:00:00", amPmAppliedTime)
    return dateTimeInISOFormat
}

const getSurveyStartDate = (props) => {
    if (props.surveyWizardProjectDetailsApi.data &&
        props.surveyWizardProjectDetailsApi.data.invitation &&
        props.surveyWizardProjectDetailsApi.data.invitation.date) {
        let startDate = props.surveyWizardProjectDetailsApi.data.invitation.date
        startDate = new Date(startDate)
        if(moment(new Date().toISOString()).isAfter(startDate.toISOString())){
            startDate = null
        }
        return startDate
    } else {
        return null
    }
}

const getSurveyCloseDate = (props) => {
    let endDate = new Date()
    endDate.setFullYear(endDate.getFullYear() + 1000)
    if (props.surveyWizardProjectDetailsApi.data &&
        props.surveyWizardProjectDetailsApi.data &&
        props.surveyWizardProjectDetailsApi.data.surveyEndDate) {
        endDate = new Date(props.surveyWizardProjectDetailsApi.data.surveyEndDate)
        endDate.setDate(endDate.getDate()+1)
    } 
    return endDate
}

export const disableDate = (current, props) => {
    let startDate  = getSurveyStartDate(props)
    let endDate = getSurveyCloseDate(props)
    return disabledDate(startDate, endDate, current)
}

const getSurveyEdingDate = (props) => {
    if (props.surveyWizardProjectDetailsApi.data &&
        props.surveyWizardProjectDetailsApi.data &&
        props.surveyWizardProjectDetailsApi.data.surveyEndDate) {
            return props.surveyWizardProjectDetailsApi.data.surveyEndDate
    }else{
        let date = new Date()
        date.setMonth(date.getMonth()+1000000)
        date = date.toISOString()
        return date
    }
}

const getSyrveyStartingDate = (props) => {
    if (props.surveyWizardProjectDetailsApi.data &&
        props.surveyWizardProjectDetailsApi.data &&
        props.surveyWizardProjectDetailsApi.data.invitation&&
        props.surveyWizardProjectDetailsApi.data.invitation.date) {
            return props.surveyWizardProjectDetailsApi.data.invitation.date
    }else{
        let date =  new Date().toISOString()
        return date
    }
}

export const checkRemiderSet = (remiderDate,props)=>{
    let startDate = getSyrveyStartingDate(props)
    let endingDate = getSurveyEdingDate(props)
    let currentDate = new Date()
    if (!moment(startDate).isSame(remiderDate)) {
        if (moment(remiderDate).isBefore(currentDate)) {
            return {
                error: true,
                message: "Reminder date is before current date!"
            }
        }
    if(!(moment(remiderDate).isAfter(startDate))){
        return {
            error:true,
            message: "Reminder date is before sending date or current date!"
        }
    }else if(moment(remiderDate).isAfter(endingDate)){
        return {
            error:true,
            message: "Reminder date is after closing date!"
        }
    }else{
        return {
            error:false,
            message: "Reminder date is after closing date!"
        }
    }
    }
    else {
        return {
            error: true,
            message: "No reminders can be set at the time of invitation"
        }
    }
}

export const getSelfCompletedDetails = (data) => {
    if (data && data.selfCompletedEmailDetails) {
        return data.selfCompletedEmailDetails
    }
    else {
        if (data && data.surveyEndDate && data.surveyEndDate !== null) {
            return constants.SELF_COMPLETED_EMAIL_TEMPLATE
        }
        else {
            return constants.SELF_COMPLETED_EMAIL_TEMPLATE_NO_DATE
        }

    }

}